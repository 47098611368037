<template>
  <div class="journals container content">
    <h1>Journals</h1>
    <div
      v-if="userData.programs && userData.programs.length"
    >
      <div
        v-for="program in userData.programs"
        :key="program.id"
        class="card m-4"
      >
        <div class="card-header">
          <h5 class="card-title">
            {{ program.title }}
          </h5>
        </div>
        <div class="card-body d-flex flex-wrap justify-content-between flex-column flex-md-row">
          <button
            class="btn"
            @click="$store.commit('activeJournalCategory', 'belief'); $store.commit('activeJournalProgram', program.id)"
          >
            Belief
          </button>
          <span />
          <button
            class="btn"
            @click="$store.commit('activeJournalCategory', 'essence'); $store.commit('activeJournalProgram', program.id)"
          >
            Essence
          </button>
          <span />
          <button
            class="btn"
            @click="$store.commit('activeJournalCategory', 'how'); $store.commit('activeJournalProgram', program.id)"
          >
            The How
          </button>
          <span />
          <button
            class="btn"
            @click="$store.commit('activeJournalCategory', 'target'); $store.commit('activeJournalProgram', program.id)"
          >
            The Target
          </button>
        </div>
        <div class="card-footer">
          <div class="">
            Started {{ date(program.created) }}
          </div>
        </div>
      </div>
    </div>
    <p v-else>
      No programs
    </p>
  </div>
</template>

<script>
export default {
  name: 'Journals',
  data() {
    return {

    };
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
};
</script>

<style lang="scss" scoped>
.journals {
  max-width: 600px;
}
button:hover {
  color: var(--primary);
}
</style>
